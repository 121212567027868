import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import platimg from "../../images/heritage-north-plat-map.jpg";
import "../../css/plans.css";

class TaylorParksPage extends React.Component {
  render(){

    let subName = 'Heritage North';

    return (
      <Layout>
        <SEO title="Plans" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4">{subName} Subdivision</h1>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm">

              <p>In the country while close to everything! This family friendly subdivision is close to schools and shopping. Quiet yet close to convenience.</p>

              <div className="row mt-4 mb-4">
                <div className="col-sm-6">
                  <div>
                    <img src={platimg} className="img-fluid" alt="alt"/>
                  </div>
                  <Link to="/contact-us"><button type="button" className="btn btn-success btn-lg mt-3">REQUEST MORE INFO</button></Link>
                </div>
                <div className="col-sm-6">

                  <iframe title="{subName} Map"  border="0" width="100%" height="500" src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d3114.1324607272477!2d-112.06719440604422!3d41.30756242240782!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sus!4v1601316538307!5m2!1sen!2sus" frameborder="0" allowfullscreen=""></iframe>
                </div>
              </div>
            </div>

          </div>

        </div>

      </Layout>
    )
  }
};

export default TaylorParksPage;
